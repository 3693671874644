@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
.intro-section-wrapper {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 65%;
  padding: 60px;
}
.intro-section-heading-name {
  color: white;
  font-size: 3.5rem;
  font-family: "Merriweather", serif;
}
.intro-section-profile-heading {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
}
.intro-section-build-things {
  color: rgba(255, 255, 255, 0.807);
  font-family: "Merriweather", serif;
  font-size: 2rem;
}
.intro-section-item {
  padding: 5px;
}
.intro-section-paragraph {
  color: white;
  width: 85%;
  font-family: "PT Sans", sans-serif;
}

@media only screen and (max-width: 600px) {
  .intro-section-wrapper {
    width: 100%;
    padding: 20px;
  }
  .intro-section-heading-name {
    font-size: 2rem;
  }
  .intro-section-build-things{
      font-size: 1rem;
  }
  .intro-section-paragraph{
      width: 100%;
  }
  .icon-bar-wrapper{
    text-align: center;
  }
}
