@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@1&display=swap');
.loading-container {
  background-color: rgb(4, 4, 17);
  width: auto;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Mono', monospace;
}
.logo {
  height: 40%;
  width: 40%;
  border-radius: 50%;
}
.loading-content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.brand {
  color: white;
}
.intro {
  color: white;
}

#slide1 {
  -webkit-animation: 3s ease 0s normal forwards 1 fadein;
  animation: 3s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#slide2 p {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
