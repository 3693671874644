.main {
  width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  border-top: 2px solid #4debc67a;
  margin-top: 40px;
}
.heading{
    padding-bottom: 10px;
}